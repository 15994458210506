<template>
  <div class="order_jspj">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true"  class="demo-form-inline">
						<el-form-item label="订单编号">
							<el-input v-model="searchText" placeholder="请输入订单编号" clearable></el-input>
						</el-form-item>
						<el-form-item label="技师名称">
							<el-input v-model="jishi_name" placeholder="请输入技师名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="订单人">
							<el-input v-model="custom_name" placeholder="请输入订单人名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="评价时间">
							<el-date-picker
					      v-model="datePicker"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
					      value-format="yyyy-MM-dd">
					    </el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<!-- 服务列表 -->
			<el-table :data="jspj_list" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="appraise_id" label="ID" width="80"></el-table-column>
				<el-table-column prop="order_sn" label="订单编号" width="180"></el-table-column>
				<el-table-column label="技师形象照" width="100">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.gzxxz_img"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="jishi_name" label="技师名称"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="consignee" label="订单人"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-for="(item,index) in form_list" :label="item.title" width="120">
					<template slot-scope="scope" v-if="item.form_type!=5">
						<div v-if="item.value_list[scope.$index].other_content">{{item.value_list[scope.$index].result_value}}({{item.value_list[scope.$index].other_content}})</div>
						<div v-else>{{item.value_list[scope.$index].result_value}}</div>
					</template>
					<template slot-scope="scope" v-else>
							<el-avatar shape="square" size="large" :src="item.value_list[scope.$index].result_value"></el-avatar>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="评价时间"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				jishi_name: "",
				custom_name: "",
				datePicker: "",
				jspj_list: [],
				form_list: [],
			};
		},
		components: {
			Pagination
		},
		created() {
			that = this;
			if (!this.$store.state.userInfo) {
	        // 防止未登录
	        this.$router.push({path: '/login'})
	    }else{
					this.getJspjList();
			}
		},
		methods: {
			getJspjList() {
				let that = this;
				var url = 'order/jspj_list';
				let params = {
					order_sn: this.searchText,
					jishi_name: this.jishi_name,
					custom_name: this.custom_name,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.jspj_list = res.list;
						that.form_list = res.form_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			search(){
				this.getJspjList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.jishi_name = "";
				this.custom_name = "";
				this.datePicker = "";
				this.getJspjList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getJspjList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getJspjList();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.order_jspj{
		.left{
		  text-align: left;
		  margin-bottom: 20px;
		}
		.demo-table-expand {
	    font-size: 0;
	  }
	  .demo-table-expand label {
	    width: 90px;
	    color: #99a9bf;
	  }
	  .demo-table-expand .el-form-item {
	    margin-right: 0;
	    margin-bottom: 0;
	    width: 50%;
	  }
	}
</style>